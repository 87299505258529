@import '../../../globalStyles/component.scss';

.container {
  @include text();
  height: 10.2rem;
  width: 10.2rem;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  .amount {
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
    color: #000000;
  }

  .title {
    font-size: 12px;
    text-align: center;
    color: $light_text2;
  }

  .amount2 {
    font-size: 18px;
    line-height: 20px;
  }

  .title2 {
  }
}
