@import '../../globalStyles/component.scss';

.dashboardContainer {
  display: flex;
  height: fit-content;
  width: 100vw;
  background-color: #f6f7fb;

  .leftSide {
    flex: 1;

    .dashboard {
      position: relative;
    }
    .infoWrapper {
      position: relative;
    }
    .input {
      padding: 15px;
      display: flex;
      border-radius: 8px;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      width: 36rem;
      margin-top: -30px !important;
      cursor: pointer;
      position: relative;

      .dateRange {
        @include text();
        display: flex;

        .dateText {
          margin: 0 1rem;
        }
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s ease-in-out;

        .calText {
          @include text();
          font-size: 20px;
          font-weight: 500;
          margin-right: 1.8rem;
        }
      }

      .rotate {
        transform: rotate(-180deg);
      }
    }

    .calendarTopWrapper {
      position: absolute;
      left: 0;
      top: 130%;
      z-index: 11;
      .wrapper {
        display: flex;
        width: 890px;
        justify-content: space-between;
        background-color: #cad2d9;

        .headingText {
          text-align: center;
          @include text();
          margin-top: 10px;
        }

        select {
          direction: ltr !important;
        }
      }
      .btnBox {
        margin-top: -40px;
        width: 890px;
        height: 8rem;
        background-color: #cbd2d9;

        display: flex;
        align-items: center;
        padding: 20px 39px;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        flex-direction: row-reverse;
        z-index: 11;

        .err {
          color: #e03838;
          font-size: 14px;
          margin-left: 5rem;
        }
      }
    }
    .background {
      position: fixed;
      inset: 0;
      z-index: 5;
    }

    .update {
      padding-top: 30px;
      padding-bottom: 10px;
    }
  }
}

.graphContainer {
  width: 100%;
  padding: 5rem;
  padding-top: 0;
}

.firstLayer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 20px;
}

.secondLayer {
  display: flex;
  justify-content: space-between;
}

// .container {
// display: flex;
// align-items: center;
// justify-content: center;

// .selectDays {
//   padding: 1.5rem 2rem 14.5rem 10rem;
//   margin-right: 13px;
//   border-radius: 9px;
//   background-color: #cbd2d9;
//   position: absolute;
//   margin-top: 19rem;
//   left: -9rem;
//   // display: none;

//   .days {
//     @include text();
//     padding: 1.3rem;
//     white-space: nowrap;
//     font-family: 'Hebbo';
//     font-size: 22px;
//     font-weight: 600;
//     line-height: 28px;
//     letter-spacing: 0.3820227086544037px;
//     text-align: right;
//     cursor: pointer;

//     &:hover {
//       background-color: #7b8795;
//       border-radius: 8px;
//       color: white;
//     }
//   }

//   .days2 {
//     @include text();
//     padding: 1.3rem;
//     white-space: nowrap;
//     font-family: 'Hebbo';
//     font-size: 22px;
//     font-weight: 600;
//     line-height: 28px;
//     letter-spacing: 0.3820227086544037px;
//     text-align: right;
//     cursor: pointer;
//   }
// }

// .one {
// margin: 2rem;
// }

// .two {
// position: absolute;
// margin-top: 4rem;
// left: 43rem;
// }
// }

.btn {
  all: unset;
  @include text();
  display: flex;

  align-items: center;
  justify-content: center;
  height: 46px;
  width: 134px;
  background-color: #323e4a;
  cursor: pointer;
  border-radius: 7px;
  color: white;

  &:active {
    opacity: 0.3;
  }
}
