.popUpUpdate {
  background-color: #f6f7fb;
  height: 460px;
  width: 450px;
}

.title {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  margin-right: 30px;
  margin-top: 40px;
  margin-bottom: 8px;
}

.description {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  margin-right: 36px;
  margin-bottom: 15px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button2 {
  background-color: #1f2933 !important;
  width: 170px;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 24px;
}

.button1 {
  background-color: #f6f7fb !important;
  width: 170px;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px;
  margin-left: 15px !important;
}

.userPayementInfo {
  background-color: #ffffff;
  border: 1px solid #dfe2eb;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  width: 90%;
  margin: auto;
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 168%;
  margin-bottom: 40px;
}

.userNameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #cbd2d9;
  height: 90px;
  width: 85%;
  margin: auto;
}

.userName {
  color: #525d71;
}

.userName2 {
  color: #525d71;
  margin-left: 10px;
}

.dateContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #cbd2d9;
  height: 60px;
  width: 85%;
  margin: auto;
}

.paymentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  width: 85%;
  margin: auto;
}

.amountContainer {
  display: flex;
  .logo {
    margin-top: 2px;
  }
}
