.tableContainer {
  height: 73vh;
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex: 1;

  .dataContainer {
    height: 8%;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4%;
    margin-bottom: 20px;

    .maximumPaymentContainer {
      display: flex;
      align-items: center;
      // width: 50%;
      flex: 1;

      .button {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-left: 30px;
        background-color: #1f2933;
        color: #f6f7fb;
        border: 1px solid #000000;
        border-radius: 4px;
        padding: 5px 15px;
        margin-right: auto;
        cursor: pointer;
      }
      .disabled {
        background-color: rgba($color: #1f2933, $alpha: 0.65);
        border: none;
      }

      .maximumPayment {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        color: #1f2933;
        margin-left: 10px;
      }

      .number {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        color: #1f2933;
        border-bottom: 1px solid black;
        margin-left: 35px;
      }
      input {
        width: 100px;
        height: fit-content;
        margin-left: 10px;
        font-weight: 600;
        font-size: 25px;
        line-height: 150%;
        color: #1f2933;
        border: none;
        border-bottom: 1px solid #1f2933;
        background-color: transparent;
        &:focus {
          outline: none;
        }
      }
      .approval {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #1f2933;
        flex: none;
        order: 0;
        flex-grow: 0;
        border: 1px solid #000000;
        border-radius: 4px;
        padding: 5px 15px;
        cursor: pointer;
        margin-left: 10px;
      }
    }
    .searchForLeadPage {
      width: 100%;
    }

    .searchContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .excel {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #7b8795;
        margin-right: 16px;
        margin-left: 33px;
      }

      .search {
        width: 421px;
        height: 52px;
        color: #7b8795;
        background-color: #ffffff;
      }
    }
  }
  .table {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: '#F5F5F5';
    flex: 1;
    border: 1px solid #cbd2d9;
    border-radius: 15px;
    background-color: #ffffff;
    overflow: hidden;
    overflow-x: auto;
  }
}

.rowStyle {
  background-color: rgba(101, 197, 206, 0.14);
  border-bottom: 1px solid #ffffff !important;
}

.iconContainer {
  display: flex;

  &:hover {
    cursor: pointer;
  }
}
