.navbar {
  background-color: #cbd2d9;
  width: 90px;
  height: 100vh;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
}
.logo {
  margin: 30px auto;
  height: fit-content;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .image {
    width: 40px;
    margin: 10px auto;
  }
  .image1 {
    width: 90%;
  }
}
.heading {
  color: #1f2933;
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  font-size: 16.1692px;
  line-height: 28px;
  text-align: center;
}

.navbarIconsContainer {
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: #f6f7fb;
  }
}
