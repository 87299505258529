.userName {
  display: flex;
  justify-content: center;
  align-items: center;

  Svg {
    height: 30px;
    width: 30px;
  }

  .nameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  .nameWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;

    .name {
      font-size: 16px;
      line-height: 24px;
      margin-left: 3px;
      color: #000000;
    }

    .dot {
      width: 2px;
      height: 2px;
      background-color: #1f2933;
      border-radius: 15px;
      margin-left: 5px;
    }
  }

  .communication {
    font-size: 12px;
    line-height: 18px;
    color: #9ea6b4;
  }
  .leadSource {
    font-size: 12px;
    line-height: 18px;
    color: #9ea6b4;
    text-align: right;
    width: 100%;
  }
}

.image {
  height: 2.8rem;
  width: 2.8rem;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
