.icon {
  margin-top: 22px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.content {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  padding-bottom: 59px;
  white-space: pre-wrap;
}

.button {
  width: 55%;
  margin-right: 90px !important;
}
