.userColumn {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  width: 17%;
  // background-color: #000000;

  .heading {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #7c7c7c;
    margin-bottom: 10px;
  }

  .number {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: right;

    color: #000000;
    margin-bottom: 44px;
  }
}
