.activityGraph {
  display: flex;
  background-color: #fff;
  width: 71%;
  height: 270px;
  border-radius: 14px;
  padding-top: 16px;

  .leftLayer {
    display: flex;
    flex-direction: column;

    .heading {
      padding-right: 24px;

      .general {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        text-align: right;
        color: #1f2933;
      }

      .date {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        text-align: right;
        color: #7b8795;
        margin-bottom: 20px;
      }
    }

    .graphPoints {
      display: flex;
      flex-direction: column;
      padding-right: 24px;
      align-items: start;

      .point {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        align-items: flex-start;

        .dot {
          width: 6px;
          height: 6px;
          border-radius: 6px;
          margin-left: 7px;
        }

        .number {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }

        .text {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #7b8795;
          width: 85px;
          margin-right: 13px;
        }
      }
    }
  }

  .rightLayer {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-right: auto;
    margin-top: 6px;

    .leads {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 5px;
      text-align: left;
      color: #323e4a;
      width: 95%;
      margin-left: 5px;
    }

    .graph {
      width: 100%;
      margin-top: -20px;
    }
  }
}
