.fifthGraph {
  display: flex;
  width: 45%;
  // height: 270px;
  background-color: #fff;
  justify-content: space-between;
  border-radius: 14px;
  padding: 2.5rem;

  .rightLayer {
    display: flex;
    flex-direction: column;
    margin-top: 1.8rem;

    .populer {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      color: #1f2933;
      white-space: nowrap;
    }

    .userName {
      display: flex;
      flex-direction: column;
      margin-top: 1.3rem;

      .itemBoxUpdate {
        margin-top: 30px;
        justify-content: flex-start;
      }

      SVG {
        width: 23px;
        height: 23px;
      }

      .userNameUpdate {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 140%;
        text-transform: uppercase;
        color: #000000;
      }
      .styleLine2 {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 140%;
        text-transform: capitalize;
        color: #5d5c5d;
      }
    }
  }
}

.leftLayer {
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  width: 65%;

  .leads {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #323e4a;
    margin-left: 7px;
    width: 93%;
    text-align: left;
  }

  .column {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    margin-left: 10px;
  }
}

.search {
  color: #7b8795;
  background-color: #ffffff;
  scale: 0.85;
  width: 110% !important;
}

.searchContainer {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
