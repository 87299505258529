.switch {
  display: flex;
  justify-content: center;
  align-items: center;

  .name {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1f2933;
    margin-right: 0px;
  }
}
