@import '../variables.scss';

@mixin typography(
  $font-family: $font-family-heebo,
  $font-weight: $font-weight-normal,
  $font-size: $font-size-normal,
  $line-height: $line-height-normal,
  $color: $black
) {
  font-size: $font-size;
  font-family: $font-family;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $color;
  font-style: normal;
  letter-spacing: 0em;
}

@mixin text {
  @include typography(
    $font-family: $font-family-heebo,
    $font-weight: $font-weight-normal,
    $font-size: $font-size-normal,
    $line-height: $line-height-normal,
    $color: $black
  );
}
