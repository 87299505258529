.icon {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 21px;
  white-space: pre-wrap;
}

.description {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 40px;
  white-space: pre-wrap;
}

.button {
  display: flex;
  justify-content: center;
}

.tryAgain {
  width: 153px;
}

.cancel {
  background-color: #ffffff !important;
  width: 153px;
  margin-left: 10px !important;
}
