.leadManagementInfo {
  height: 100px;
  width: 100px;
  background-color: #f6f7fb;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 9px;
  border-radius: 8px;

  .number {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: #000000;
  }

  .info {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #7b8795;
  }
}
