.popUpWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.background {
  // background-color: #1f2933;
  // position: absolute;
  // height: 100vh;
  // width: 100vw;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // opacity: 0.6;
  // cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #1f2933;
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.popUp {
  background-color: #ffffff;
  height: 400px;
  width: 400px;
  box-shadow: 0px 7.42706px 27.5862px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hidePopUp {
  display: none;
}
