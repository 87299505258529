@import '../../globalStyles/component.scss';

.input {
  width: 100%;
  height: 4.2rem;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &:active {
    border-color: #000;
  }
}

.label {
  font-size: 14px !important;
}
.new {
  background-color: red;
}
.focused {
  border-color: #000;
  // background-color: rgba($color: #fff, $alpha: 0.8);
  // opacity: 0.5;
}

.container {
  display: flex;
  flex-direction: column;
  height: 45px;
}

.error {
  padding-top: 10px;
  padding-right: 10px;
  color: red;
  font-size: 12px;
}
