.thirGraph {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // height: 270px;
  background-color: #fff;
  width: 25%;
  border-radius: 14px;
  padding-right: 22px;
}

.total {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  margin-bottom: 13px;
  margin-top: 7px;
}

.number {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 47px;
  text-align: center;
  color: #000000;
}

.chart {
  display: flex;
  width: 90%;
  align-items: flex-end;
  padding-right: 10px;
}
