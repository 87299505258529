.chart {
  background-color: #ffff;
  width: 27%;
  height: 270px;
  border-radius: 14px;

  .heading {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    width: 90%;
    margin: auto;

    .payments {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      text-align: right;
      color: #1f2933;
    }

    .leads {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #323e4a;
      margin-left: 5px;
    }
  }
  .graphWrapper {
    width: 138px;
    height: 138px;
    position: relative;
    margin: 0 auto;
    margin-top: 11px;
    margin-bottom: 23px;

    .graphContainer {
      width: 150px;
      height: 150px;
      position: absolute;
      top: -20px;
      left: -7px;
    }

    .graphInfo {
      width: 138px;
      height: 138px;
      background: #f6f7fb;
      border-radius: 50%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      :first-child {
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        color: #000000;
      }

      .text {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #7b8795;
        margin-top: 1px;
        margin-bottom: 4px;
      }
      :last-child {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #1f2933;
      }
    }
  }

  .list {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-left: 4px;
    background-color: #7b8795;
  }

  .totalPayments {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #7b8795;
    padding-right: 4px;
  }
}
