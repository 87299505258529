@import '../../globalStyles/component.scss';

@keyframes shake {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(5px);
  }
  40% {
    transform: translate(-5px);
  }
  60% {
    transform: translate(5px);
  }
  80% {
    transform: translate(-5px);
  }
  100% {
    transform: translate(0);
  }
}

.page {
  width: 100vw;
  height: 100vh;
  background-color: #1f2933;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    width: 77.5rem;
    background-color: $white;
    height: 75vh;
    max-height: 585px;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    .headText {
      font-family: $font-family-pacifico;
      font-size: 50px;
      font-weight: 400;
      line-height: 88px;
      letter-spacing: 0em;
      text-align: center;
      margin-top: 7.6vh;
      color: $black;
    }

    .mainBox {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      flex-direction: row-reverse;

      .icon {
        margin-left: 7.4rem;
        margin-bottom: -2px;
      }

      .form {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 9rem;
        width: 29rem;
        height: 100%;

        .header {
          font-family: $font-family-heebo;
          font-size: 32px;
          font-weight: 700;
          line-height: 47px;
          letter-spacing: 0em;
          text-align: right;
          width: 100%;
          color: $black;
          margin-top: 1rem;
        }

        .subHead {
          font-family: $font-family-heebo;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0em;
          text-align: right;
          width: 100%;
          margin-top: 1.3rem;
          color: $black;
        }

        .inputBox {
          margin-top: 3.8rem;
          width: 100%;

          .input {
            margin-top: 2.4rem;
          }
        }

        .forgetPassword {
          font-family: $font-family-heebo;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          cursor: pointer;
          margin-top: 0.7rem;
          text-decoration: underline;
          color: #7b8795;
        }

        .error {
          font-family: $font-family-heebo;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: center;
          width: 100%;
          color: $error;
          margin-top: 3.5rem;
        }
        .shake {
          animation: shake 0.3ms infinite;
        }

        .btn {
          width: 100%;
          margin-top: 6.5rem;
        }

        .btn2 {
          margin-top: 1.1rem !important;
        }
      }
    }
  }
}
