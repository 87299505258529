.paymentTransfer {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: #f6f7fb;
}
.mainPage {
  flex: 1;
}

.header {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7b8795;
}

.date {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.sum {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #1f2933;
}

.status {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1f2933;
}

.userName {
  line-height: 18px !important;
  color: #7b8795 !important;
}

.btn1 {
  height: 33px;
}

.rowStyle {
  background-color: rgba(101, 197, 206, 0.14);
  border-bottom: 1px solid #ffffff !important;
}

.titleStyle {
  padding-top: 20px;
  padding-bottom: 30px;
}

.buttonStyle {
  width: 290px;
}
