@import '../../globalStyles/component.scss';

.Container {
  display: flex;
  height: fit-content;
  width: 100vw;
  height: 100vh;
  background-color: #f6f7fb;
  .mainPage {
    flex: 1;

    .secondLayer {
      display: flex;
      align-items: center;
      padding-right: 9rem;

      .dropdown {
        margin-left: 33px;
        width: 365px;
        background-color: #fff;
      }

      .button {
        width: 112px;
        height: 45px;
        background-color: #323e4a;
        color: #e4e4e4;
        border-radius: 8px;
        margin-right: 26px;
      }
    }
  }

  .infoWrapper {
    position: relative;
  }
  .input {
    padding: 15px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    width: 36rem;
    // margin-top: -30px !important;
    cursor: pointer;
    position: relative;

    .dateRange {
      @include text();
      display: flex;

      .dateText {
        margin: 0 1rem;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s ease-in-out;

      .calText {
        @include text();
        font-size: 20px;
        font-weight: 500;
        margin-right: 1.8rem;
      }
    }

    .rotate {
      transform: rotate(-180deg);
    }
  }

  .calendarTopWrapper {
    position: absolute;
    z-index: 11;
    right: -34px;

    .wrapper {
      display: flex;
      width: 890px;
      justify-content: space-between;
      background-color: #cad2d9;

      .headingText {
        text-align: center;
        @include text();
        margin-top: 10px;
      }

      select {
        direction: ltr !important;
      }
    }
    .btnBox {
      margin-top: -40px;
      width: 890px;
      height: 8rem;
      background-color: #cbd2d9;

      display: flex;
      align-items: center;
      padding: 20px 39px;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      flex-direction: row-reverse;
      z-index: 11;

      .err {
        color: #e03838;
        font-size: 14px;
        margin-left: 5rem;
      }
    }
  }
  .background {
    position: fixed;
    inset: 0;
    z-index: 5;
  }

  .update {
    padding-top: 30px;
    padding-bottom: 10px;
  }
}

.btn {
  all: unset;
  @include text();
  display: flex;

  align-items: center;
  justify-content: center;
  height: 46px;
  width: 134px;
  background-color: #323e4a;
  cursor: pointer;
  border-radius: 7px;
  color: white;

  &:active {
    opacity: 0.3;
  }
}
