@import '../../globalStyles/component.scss';

.container {
  @include text();

  padding-left: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3.5rem;
  background-color: white;
  z-index: 1000;
  padding-bottom: 2rem;
  overflow-y: auto;
  overflow-x: hidden;

  .box {
    margin-top: 2rem;
    .line1 {
      font-size: 15px;
      line-height: 22px;
      text-align: right;
    }

    .amount {
      font-size: 20px;
      font-weight: 500;
      line-height: 29px;
      text-align: right;
    }
  }

  .progressBar {
    margin-top: 1rem;
    display: flex;
    align-items: center;

    .progressBox {
      background-color: $white;
      width: 38rem;
      border-radius: 10rem;

      .bar {
        background-color: $light_text2;
        height: 1rem;
        border-radius: 10rem;
      }
    }

    .amount2 {
      text-align: right;
      margin-right: 1.7rem;
    }
  }

  .boxContainer {
    display: flex;
    margin-top: 3rem;
    justify-content: space-between;
    width: 100%;
  }

  .textBox1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1.4rem;

    .text {
      text-align: right;
    }
    .text1 {
      text-align: right;
    }
  }

  .lineBreak {
    height: 1px;
    width: 100%;
    margin-top: 1.5rem;
    background-color: #cbd2d9;
  }

  .inputContainer {
    margin-top: 3rem;
    width: 100%;

    .inputBox {
      margin-top: 4rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .input {
        width: 96%;
      }
    }
  }

  .btn {
    margin-top: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;

    .button {
      width: 28rem;
    }
  }

  .btn2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.8rem;
  }

  .deleteSection {
    width: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent !important;

    .deleteText {
      text-align: center;
      margin-right: 1rem;
      color: black;
    }
  }
  .line2 {
    color: #7b8795;
  }
}
