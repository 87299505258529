.upperLayer {
  flex: 1;
}

.leadManagementContainer {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #f6f7fb;
}

.header1 {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7b8795;
  padding-right: 1.7% !important;
}

.header {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7b8795;
}

.content {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1f2933;
}

.userIcon {
  padding-right: 1.3% !important;
}

.pencil {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;

  &:hover {
    background-color: #e4e7ec;
  }
}

.delete {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;

  &:hover {
    background-color: #e4e7ec;
  }
}

// .table {
//   height: 100%;
// }
