@import '../../globalStyles/component.scss';

.itemBox {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    overflow: hidden;
  }

  .box {
    margin-right: 1rem;

    .line1 {
      @include text();
      font-size: 12px;
      .line1Text1 {
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        margin-left: 1.3rem;
      }
    }

    .userName {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #1f2933;
    }

    .line2 {
      @include text();
      font-size: 12px;
      text-align: right;
      display: flex;

      .date {
        font-weight: 400;
        font-size: 12px;
        padding-right: 2px;
        color: #000000;
      }
    }
  }
}
