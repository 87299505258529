.chart {
  background-color: #ffff;
  width: 27%;
  // height: 270px;
  border-radius: 14px;

  .heading {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    width: 80%;
    margin: auto;

    .payments {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      text-align: right;
      color: #1f2933;
    }

    .iconContainer {
      display: flex;
      align-items: center;
      z-index: 1;

      &:hover {
        cursor: pointer;
      }

      .leads {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #323e4a;
        padding-left: 7px;
      }
    }
  }

  .graphWrapper {
    width: 138px;
    height: 138px;
    position: relative;
    margin: 0 auto;
    margin-top: 11px;
    margin-bottom: 23px;

    .graphContainer {
      width: 231px;
      height: 231px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      div {
        transform: rotate(45deg);
      }
    }

    .graphInfo {
      width: 138px;
      height: 138px;
      border-radius: 50%;
      margin: auto;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      :first-child {
        margin-top: -10px;
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        text-align: center;
        color: #000000;
      }
    }
  }

  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: auto;
  }

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 8px;
    margin-left: 8px;
    background-color: #7b8795;
  }

  .totalPayments {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
  }
}
