.phoneVerificationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Heebo';
  font-style: normal;
  height: 100vh;
  width: 100vw;

  .phoneVerification {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 597px;
    // width: clamp(440px, 41.5%, 520px);
    height: 710px;
    // height: clamp(550px, 64%, 655px);
    background: #ffffff;
    border-radius: 14px;
    position: relative;

    .mainIcon {
      display: flex;
      transform: translateY(-75%);
      margin-bottom: -140px;
      // margin-bottom: -30%;
    }

    .title {
      font-weight: 700;
      font-size: 22px;
      line-height: 32px;
      color: #1f2933;
      padding-top: 35px;
      // padding-top: 6.5%;
    }

    .box {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 308px;
      // width: 53%;
      height: 200px;
      border: 0.5px solid #1f2933;
      border-radius: 14px;
      padding-top: 13px;
      margin-top: 3%;

      .userData {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        .image {
          height: 2.8rem;
          width: 2.8rem;

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            border-radius: 50%;
          }
        }

        .dataContainer {
          display: flex;
          flex-direction: column;
          margin-right: 12px;

          .data {
            display: flex;
            justify-content: center;
            align-items: center;

            .companyName {
              width: 25px;
              height: 22px;
              font-family: 'Open Sans';
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #000000;
              margin-left: 8px;
            }

            .line {
              width: 1px;
              height: 10px;
              transform: rotate(180deg);
              border: 0.5px solid #9ea6b4;
            }

            .catagory {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 19px;
              color: #9ea6b4;
              margin-right: 8px;
            }
          }
          .subCatagory {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 19px;
            color: #121314;
          }
        }
      }
      .value {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        .valuation {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 30.8598px;
          line-height: 42px;
          color: #222222;
          margin-left: 4px;
        }

        .sign {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14.243px;
          line-height: 19px;
          color: #222222;
          height: 8px;
        }
      }
      .description {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 152%;
        color: #121314;
        white-space: pre-wrap;
        text-align: center;
      }
    }
    .number {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #1f2933;
      margin-top: 40px;
      margin-bottom: 23px;
    }

    .code {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #1f2933;
      text-align: center;
      white-space: pre-wrap;
      margin-top: 26px;
    }

    .otpContainer {
      display: flex;
      justify-content: space-between;
      width: 280px;

      .otp_code {
        height: 45px;
        width: 45px !important;
        background: #ffffff;
        // border: 2px solid #1f2933;
        border-radius: 6px;
        margin-top: 20px;
      }
    }

    .circle {
      width: 58.25px;
      height: 58.25px;
      border: 3px solid #7b8795;
      border-radius: 50%;
      margin-top: 5%;
    }

    .newOTP {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      color: #1f2933;
      margin-top: 20px;
      margin-bottom: 15px;

      .sendmeNewotp {
        color: #cbd2d9;
        text-decoration: underline;
      }
    }

    .input {
      box-sizing: border-box;
      border-color: #7b8795;
      border-radius: 4px;
      width: 300px;
      height: 42px;
    }

    .generalErrors {
      color: red;
      font-size: 12px;
      margin-bottom: 20px;
    }

    .button {
      width: 235px;
    }

    @media (max-width: 650px) {
      scale: 80%;
      min-width: 380px;
      height: 610px;

      .otpContainer {
        width: 260px;
      }

      .input {
        width: 270px;
        height: 42px;
      }
      .button {
        width: 200px;
      }
    }
  }
}

.base_timer {
  position: relative;
  width: 58px;
  height: 58px;
  margin-top: 20px;
}

.base_timer__svg {
  transform: scaleX(-1);
}

.base_timer__circle {
  fill: none;
  stroke: none;
}

.base_timer__path_elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base_timer__path_remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base_timer__label {
  position: absolute;
  width: 58px;
  height: 58px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
