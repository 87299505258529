.Snackbar {
  position: fixed;
  bottom: 44px;
  right: 100px;
  display: flex;
  height: 45px;
  width: 357px;
  box-shadow: 0px 2px 8px rgba(158, 166, 180, 0.4);
  border-radius: 10px;
  align-items: center;
  background: #6c6c6e;
  display: none;

  .crossIcon {
    padding-left: 11px;
    padding-right: 60px;
  }
  .desc {
    display: flex;
    height: 40px;
    width: 264px;
    align-items: center;
    a {
      all: unset;
      cursor: pointer;
    }

    .Link {
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
      border-bottom: 0.5px solid #ffffff;
    }
    .information {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
      padding-left: 6px;
    }
  }
}
