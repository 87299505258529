.logoContainer {
  display: flex;
  align-items: center;
  margin-bottom: 7%;
  margin-top: 6%;
  margin-right: 2%;

  .icon {
    margin-left: 3%;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 80px;
      width: 80px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .package {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  .internet {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #9ea6b4;
  }
}

.leadManagementInfo {
  display: flex;
  margin-bottom: 8%;
  margin-right: 13%;
}

.line {
  border: 1px solid #cbd2d9;
  margin-top: 7%;
  width: 96%;
  margin-bottom: 7%;
}

.update {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #7b8795;
  margin-bottom: 8%;
}

.updateContainer {
  display: flex;
  align-items: center;
  height: 60%;
  width: 430px;
  flex-direction: column;

  .updateFields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 12%;

    .textStyle {
      width: 100%;
    }
  }
  .updateFields2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 9%;

    .textStyle {
      width: 75%;
    }
  }

  .description {
    padding-bottom: 20px;
    width: 100% !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 14px !important;
    line-height: 152%;
    display: flex;
    align-items: center;
    text-align: right;
    color: #1f2933;
  }
  .text {
    padding: 0;
  }
  .error {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 15px;
    text-align: right;
    padding-right: 10px;
    color: red;
    font-size: 12px;
  }
  .updateButton {
    width: 67.5%;
    margin-bottom: 3.7% !important;
  }

  .deleteButton {
    display: flex;
    background-color: #fff !important;
    width: 67.5%;
  }
}
