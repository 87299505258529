.navbarIconsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90px;
  height: 90px;
  color: #7b8795;
  &:hover {
    color: #1f2933;

    svg {
      rect {
        fill: #1f2933;
      }
      path {
        fill: #1f2933;
        stroke: white;
      }
    }
  }
}

.active {
  color: #1f2933;
  background-color: #f6f7fb;

  svg {
    rect {
      fill: #1f2933;
    }
    path {
      fill: #1f2933;
      stroke: white;
    }
  }
}

.iconName {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
