.muirtl-pdct74-MuiTablePagination-selectLabel {
  font-size: 14px !important;
}

.MuiDataGrid-row.Mui-selected {
  border-right: 7px solid #7b8795 !important;
  background-color: #ffffff !important;
}

.MuiDataGrid-row:hover {
  background-color: #ffffff !important;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25) !important;
  border-right: 7px solid #7b8795 !important;
  cursor: pointer !important;
}

.muirtl-1e2bxag-MuiDataGrid-root,
.MuiDataGrid-columnHeader:focus,
.muirtl-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.muirtl-194a1fa-MuiSelect-select-MuiInputBase-input.muirtl-194a1fa-MuiSelect-select-MuiInputBase-input.muirtl-194a1fa-MuiSelect-select-MuiInputBase-input {
  font-size: 14px !important;
}

.muirtl-194a1fa-MuiSelect-select-MuiInputBase-input.MuiSelect-select {
  min-height: unset !important;
  margin-top: 2px !important;
}

.muirtl-levciy-MuiTablePagination-displayedRows {
  font-size: 14px !important;
  width: 11rem;
}

.muirtl-1e2bxag-MuiDataGrid-root,
.MuiDataGrid-columnHeader:focus-within,
.muirtl-1e2bxag-MuiDataGrid-root,
.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

/* Phone number coloum fix */
.muirtl-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderDraggableContainer,
.muirtl-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer {
  flex-direction: row !important;
}

.muirtl-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell--textRight {
  justify-content: flex-start !important;
}

/* Header Color */

.muirtl-1e2bxag-MuiDataGrid-root,
.MuiDataGrid-columnHeaderTitleContainer {
  background-color: #f7f8f9;
  overflow: hidden;
}

.muirtl-i4bv87-MuiSvgIcon-root {
  font-size: 2rem !important;
}
