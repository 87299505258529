.iconHover {
  height: fit-content;
  width: fit-content;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  &:hover {
    background-color: #e4e7ec;
  }
}

.row {
  background-color: #ffffff;
}

.balanceText {
  font-weight: 700;
  font-size: 16px;
}

.header {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7b8795;
}

.columnStyle {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.pencil {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;

  &:hover {
    background-color: #e4e7ec;
  }
}

.delete {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;

  &:hover {
    background-color: #e4e7ec;
  }
}
