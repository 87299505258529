// COLORS
$primary: #1f2a33;
$white: #f6f7fb;
$black: #1f2933;
$error: #e03838;
$primary_grey: #cbd2d9;
$light_text: #52606d;
$light_text2: #7b8795;

$error-light: #f2b8b8;
$success: #00ba88;
$logo-text: #000000;
$cyan-blue: #f0f2f4;
$gray-white: #f9fafb;

// FONT SIZES
$font-size-logo: 4.2rem;
$font-size-title: 2.2rem;
$font-size-normal: 1.4rem;
$font-size-medium: 1.6rem;
$font-size-small: 0.8rem;

// LINE HEIGHTS
$line-height-logo: 6.6rem;
$line-height-title: 3.6rem;
$line-height-normal: 2.4rem;
$line-height-medium: 1.9rem;
$line-height-small: 2rem;

// FONT WEIGHTS
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-extra-bold: 700;
$font-weight-plus-extra-bold: 800;
$font-weight-black: 900;

// FONT FAMILY
$font-family-heebo: 'Heebo', sans-serif;
$font-family-pacifico: 'Pacifico', cursive;
$font-family-default: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
  Droid Sans, Helvetica Neue, sans-serif;
