.popUp {
  height: 480px;
  width: 436px;
}

.mainIcon {
  position: absolute;
  // align-self: center;
  // transform: translateY(-18);
  z-index: 2;
}

.title {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.communicationContainer {
  display: flex;
  //   justify-content: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
}

.leadContainer {
  background-color: #f6f7fb;
  width: 70%;
  margin: auto;
  border-radius: 14px;
  color: #121314;
}

.wrap {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.internet {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  //   text-align: center;
}

.NIS {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.number {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30.8598px;
  line-height: 42px;
  color: #222222;
}

.NIS1 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14.243px;
  line-height: 19px;
  margin-top: 12px;
  margin-right: 4px;
}

.description {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 152%;
  text-align: center;
  white-space: pre-wrap;
  margin-top: 10px;
  color: #121314;
  padding-bottom: 12px;
}

.link {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  padding-bottom: 20px;
}

.publishLead {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 20px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit {
  background-color: #ffffff !important;
  width: 153px;
  margin-left: 10px;
}

.publish {
  width: 153px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;

  .imageContainer {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    margin-right: 15px;
    .hotContainer {
      display: flex;
      align-items: center;

      .hot {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
      }

      .line {
        width: 10px;
        transform: rotate(90deg);
        border: 0.5px solid #9ea6b4;
      }

      .communication {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 19px;
        color: #9ea6b4;
      }
    }
  }
}
