@import '../../globalStyles/component.scss';

.leftSideDrawerWrapperContainer {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.sideDrawerVisbile {
  transform: translateX(0) !important;
  box-shadow: 7px 0px 16px #e4e7ec;
}

.leftSideDrawerWrapper {
  flex-direction: column;
  display: flex;

  background-color: #ffffff;
  height: 100vh;
  width: 509px;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 3.3%;
  padding-right: 2.2%;
  transform: translateX(-100%);
  transition: transform 500ms ease;
  z-index: 2;
  overflow: scroll;
  scroll-behavior: smooth;

  svg {
    &:hover {
      cursor: pointer;
    }
  }

  .heading {
    height: 40px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 47px;
    padding-right: 20px;
  }
}

.header {
  display: flex;
  align-items: center;

  .iconContainer {
    transform: rotate(180deg);
  }
}
