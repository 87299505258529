.upperLayer {
  display: flex;
  flex-direction: column;
  height: 26vh;
  width: 100%;
  padding-right: 9rem;
  padding-left: 5rem;
  padding-top: 30px;

  .firstRow {
    display: flex;
    justify-content: space-between;
    height: 50%;

    .leadManagement {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 47px;
      color: #000000;
      margin-top: 1rem;
    }

    .dateContainer {
      display: flex;

      .asOfDate {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: #52606d;
        margin-left: 11px;
      }

      .date {
        direction: ltr;
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: #52606d;
      }
    }

    .leftWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 25%;
      height: 60px;
      background-color: #cbd2d9;
      border-radius: 100px;
      font-family: 'Heebo';
      font-style: normal;
      color: #000000;

      .callCenter {
        margin-right: 30px;
        width: 50%;

        .name {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }

        .call {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
      }

      .disconnect {
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-left: 35px;
      }
    }
  }

  .bottomLayer {
    height: 50%;
    margin-top: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
    width: 36.5%;

    .totalLeads1 {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 29px;
      color: #52606d;
    }

    .leadNumber {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 47px;
      color: #000000;
      margin-top: 8px;
    }

    .newLeads {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 29px;
      color: #52606d;
    }

    .newLeadNumber {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 47px;
      color: #000000;
      margin-top: 8px;
    }
  }
}
