.deleteUser {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: #1f2933;
  padding-top: 74px;
  padding-bottom: 32px;
}

.confirmDeleteUser {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #1f2933;
  padding-bottom: 100px;
  margin: auto;
  white-space: pre-wrap;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  .button2 {
    width: 38%;
  }

  .button1 {
    background-color: #ffffff !important;
    width: 38%;
    margin-left: 10px;
  }
}
